import { ELanguage, ELocale } from 'types';
import { useMemo } from 'react';
import Germany from 'assets/flags/germany.svg?react';
import Norway from 'assets/flags/norway.svg?react';
import Sweden from 'assets/flags/sweden.svg?react';
import Uk from 'assets/flags/uk.svg?react';
import useBUContent from './useBUContent';

type useLanguageResult = {
    getValidLanguage: (selectedLocale: ELocale) => LanguageItem;
    getLanguageList: () => LanguageItem[];
    getValidBULanguage: (selectedLocale: ELocale) => ELocale;
};
interface LanguageItem {
    name: ELanguage;
    value: ELocale;
    flag?: React.JSX.Element;
}
const Flags = {
    [ELocale.EN]: <Uk />,
    [ELocale.DE]: <Germany />,
    [ELocale.NO]: <Norway />,
    [ELocale.SV]: <Sweden />,
    [ELocale.MASTER]: undefined,
};

export const useLanguage = (): useLanguageResult => {
    const {
        buContent: { availableLanguages = [] },
    } = useBUContent();

    const getValidLanguage = useMemo(
        () =>
            (selectedLocale: ELocale | null): LanguageItem => {
                const isValidLocale =
                    (selectedLocale && availableLanguages.includes(selectedLocale)) ||
                    (import.meta.env.VITE_APP_SHOW_MASTER_LANGUAGE === 'true' && selectedLocale === ELocale.MASTER);
                const locale = isValidLocale ? selectedLocale : ELocale.EN;
                return {
                    name: ELanguage[locale],
                    value: locale,
                    flag: Flags[locale],
                };
            },
        [availableLanguages],
    );

    const getValidBULanguage = useMemo(
        () =>
            (selectedLocale: ELocale): ELocale => {
                const isValidLocale =
                    (selectedLocale && availableLanguages.includes(selectedLocale)) ||
                    (import.meta.env.VITE_APP_SHOW_MASTER_LANGUAGE === 'true' && selectedLocale === ELocale.MASTER);
                return isValidLocale ? selectedLocale : availableLanguages[0];
            },
        [availableLanguages],
    );

    const getLanguageList = useMemo(
        () => (): LanguageItem[] => {
            const languageList = availableLanguages.map((lang) => ({
                name: ELanguage[lang],
                value: lang,
                flag: Flags[lang],
            }));
            if (import.meta.env.VITE_APP_SHOW_MASTER_LANGUAGE === 'true') {
                languageList.unshift({ name: ELanguage[ELocale.MASTER], value: ELocale.MASTER, flag: undefined });
            }
            return languageList;
        },
        [availableLanguages],
    );

    return {
        getValidLanguage,
        getLanguageList,
        getValidBULanguage,
    };
};
